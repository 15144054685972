import React, { useState } from 'react';

const Collapsible = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <button
                className="text-left w-full text-blue-500 font-semibold py-2"
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
            </button>
            {isOpen && (
                <div className="text-gray-600 text-md mb-4 mx-auto max-w-4xl">
                    {children}
                </div>
            )}
        </div>
    );
};

export default Collapsible;

import React from 'react';

const ButtonGroup = ({ onAction }) => {
    const buttons = [
        { name: 'Sentence case', action: 'sentenceCase' },
        { name: 'UPPER CASE', action: 'uppercase' },
        { name: 'lower case', action: 'lowercase' },
        { name: 'Title Case', action: 'titleCase' },
        { name: 'Capitalized Case', action: 'capitalizedCase' },
        { name: 'iNVERSE', action: 'inverse' },
        { name: 'aLterNate', action: 'alternateText' },
        { name: 'Wingdings Converter', action: 'wingdingsConverter' },
        { name: 'Gothic Converter', action: 'gothicConverter' },
    ];

    return (
        <div className="flex flex-wrap justify-center gap-2 mt-4">
            {buttons.map((button) => (
                <button
                    key={button.action}
                    className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                    onClick={() => onAction(button.action)}
                >
                    {button.name}
                </button>
            ))}
        </div>
    );
};

export default ButtonGroup;

import React from 'react';

const TextArea = ({ text, setText, fontFamily }) => {
    return (
        <textarea
            id="textArea"
            style={{ fontFamily }}
            className="w-full p-4 text-gray-700 bg-white border rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            rows="10"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your text here..."
        ></textarea>
    );
};

export default TextArea;

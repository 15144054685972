import React, {useState} from 'react';
import TextArea from './components/TextArea';
import ButtonGroup from './components/ButtonGroup';
import './App.css';
import Collapsible from "./components/Collapsible";

const App = () => {
    const defaultFont = 'Arial';
    const alternativeFont = 'MyWingdings';
    
    const [fontFamily, setFontFamily] = useState(defaultFont); // Default font
    const [selectedFont, setSelectedFont] = useState(defaultFont);

    const toggleFont = () => {
        const newFont = selectedFont === defaultFont ? alternativeFont : defaultFont;
        setSelectedFont(newFont);
        setFontFamily(newFont);
    };

    const [text, setText] = useState('');

    const gothicMap = {
        'a': '𝔞', 'b': '𝔟', 'c': '𝔠', 'd': '𝔡', 'e': '𝔢',
        'f': '𝔣', 'g': '𝔤', 'h': '𝔥', 'i': '𝔦', 'j': '𝔧',
        'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫', 'o': '𝔬',
        'p': '𝔭', 'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱',
        'u': '𝔲', 'v': '𝔳', 'w': '𝔴', 'x': '𝔵', 'y': '𝔶',
        'z': '𝔷',
        'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈',
        'F': '𝔉', 'G': '𝔊', 'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍',
        'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑', 'O': '𝔒',
        'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗',
        'U': '𝔘', 'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜',
        'Z': 'ℨ',
        // Add mappings for numbers if desired, though Gothic numerals aren't directly available in Unicode.
    };

    const handleDownloadText = () => {
        // Create a blob with the text content and type set to text/plain
        const blob = new Blob([text], {type: 'text/plain'});
        const url = URL.createObjectURL(blob);

        // Create a link element, set the href to the blob URL, and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'downloaded-text.txt'; // Name of the file to be downloaded
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Programmatically click the link to trigger the download

        document.body.removeChild(link); // Clean up by removing the link
        URL.revokeObjectURL(url); // Free up memory by releasing the blob URL
    };

    // Function to handle text manipulation based on action
    const handleAction = (action) => {
        const shortWords = ["am", "is", "are", "the", "of", "in", "on", "at", "to", "as", "by", "an", "and", "but", "or", "for", "nor", "etc"];

        const applyTransformation = (text, action) => {
            switch (action) {
                case 'uppercase':
                    return text.toUpperCase();
                case 'lowercase':
                    return text.toLowerCase();
                case 'inverse':
                    return text.split('').map(char =>
                        char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
                    ).join('');
                case 'sentenceCase':
                    return text.toLowerCase().replace(/(^\w{1}|\.\s*\w{1})/gi, letter => letter.toUpperCase());
                case 'capitalizedCase':
                    return text.replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
                case 'alternateText':
                    return text.split('').map(char =>
                        Math.random() > 0.5 ? char.toUpperCase() : char.toLowerCase()
                    ).join('');
                case 'titleCase':
                    return text.toLowerCase().split(' ').map((word, index) => {
                        // Always capitalize the first word and one-letter words
                        if (index === 0 || word.length === 1) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                        }
                        // Capitalize words not in the list of short words
                        if (!shortWords.includes(word)) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                        }
                        return word; // Return the word unchanged if it's a short word
                    }).join(' ')
                case 'copyToClipboard':
                    if (navigator.clipboard && window.isSecureContext) {
                        // Navigator clipboard api method'
                        navigator.clipboard.writeText(text).then(() => {
                            alert("Text copied to clipboard");
                        }, (err) => {
                            console.error("Failed to copy text: ", err);
                        });
                    } else {
                        // Textarea method
                        let textArea = document.createElement("textarea");
                        textArea.value = text;
                        document.body.appendChild(textArea);
                        textArea.focus();
                        textArea.select();
                        try {
                            document.execCommand('copy');
                            alert("Text copied to clipboard");
                        } catch (err) {
                            console.error('Failed to copy text: ', err);
                        }
                        document.body.removeChild(textArea);
                    }
                    return text;
                case 'clear':
                    return '';
                case 'wingdingsConverter':
                    toggleFont();
                    return text;
                case 'gothicConverter':
                    return text.split('').map(char =>
                        gothicMap[char] || char // Default to original char if no Gothic equivalent exists
                    ).join('');
                default:
                    return text;
            }
        }
        // Assuming you have a ref to the textarea. If not, create one.
        const textarea = document.getElementById('textArea'); // If using refs: textareaRef.current
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        // Check if there's a selection
        if (start !== end) {
            const beforeText = text.substring(0, start);
            const selectedText = text.substring(start, end);
            const afterText = text.substring(end);

            // Apply transformation to the selected text only
            const transformedText = applyTransformation(selectedText, action);

            // Update the text with the transformed selection
            setText(beforeText + transformedText + afterText);

            // Optional: re-select the text that was transformed
            textarea.focus(); // Focus on the textarea to ensure correct update of selection
            textarea.setSelectionRange(start, start + transformedText.length);
        } else {
            // Apply transformation to the entire text if no selection
            setText(applyTransformation(text, action));
        }
    };

    const countSentences = (text) => {
        // Basic sentence detection based on punctuation. This regex matches '.', '?', and '!' characters that are likely to indicate the end of a sentence.
        const sentences = text.match(/[\w|)][.?!](\s|$)/g);
        return sentences ? sentences.length : 0;
    };

    return (
        <div className="min-h-screen bg-gray-100 pt-8 pb-4 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-3xl mx-auto">

                <h1 className="text-2xl font-bold text-center mb-4">Convert Gizmo</h1>

                <Collapsible title="🌟 How It Works">
                    Your go-to web tool for smooth text transformations. Toggling between cases, crafting capitalized
                    sentences. Easily reverse your text with a click and download. Seamless text manipulation!
                </Collapsible>

                <TextArea text={text} setText={setText} fontFamily={fontFamily}/>

                <div className="mt-4 text-center">
                    <p>Words: {text.split(/\s+/).filter(Boolean).length} &nbsp;|&nbsp; Characters: {text.length} &nbsp;|&nbsp; Sentences: {countSentences(text)}</p>
                </div>

                <ButtonGroup onAction={handleAction}/>

                {/* Add any additional components or features here */}
                <div className="flex justify-center mt-4 gap-2">
                    <button
                        className="px-4 py-2 text-sm text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={handleDownloadText}
                    >
                        Download Text
                    </button>
                    <button
                        key='copyToClipboard'
                        className="px-4 py-2 text-sm text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={() => handleAction('copyToClipboard')}
                    >
                        Copy to Clipboard
                    </button>
                    <button
                        key='clear'
                        className="px-4 py-2 text-sm text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={() => handleAction('clear')}
                    >
                        Clear
                    </button>
                </div>
                <div className="flex justify-center mt-4 gap-2">
                    <a href="https://www.buymeacoffee.com/hiienko" target="_blank"> {/* eslint-disable-line react/jsx-no-target-blank */}
                        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee"
                             style={{height: '60px', width: '217px'}}/>
                    </a>
                </div>
            </div>
        </div>
    );

};

export default App;
